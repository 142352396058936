*,
::after,
::before {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  font-family: Roboto, sans-serif;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  min-width: 100vw;
  max-width: 100vw;
  overflow: hidden auto;
}

.bodyOpenModal {
  overflow: hidden;
}

.ReactModal__Content,
.ReactModal__Overlay {
  overflow: hidden scroll;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 8px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 8px;
  font-weight: 500;
  line-height: 1.2;
}
h5 {
  font-size: 20px;
}
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  flex: 1 1 0%;
}
sup {
  top: -8px;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
button,
input {
  overflow: visible;
}

button:active {
  outline: none;
  box-shadow: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
label {
  display: inline-block;
  margin-bottom: 8px;
}
p {
  margin-top: 0;
  margin-bottom: 16px;
}
.d-none {
  display: none !important;
}

.container {
  padding-right: 8px;
  padding-left: 8px;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
[class*="col-"] {
  padding: 15px;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-brand {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  color: rgba(0, 0, 0, 0.9);
  margin-right: 16px;
  font-size: 20px;
  line-height: inherit;
  white-space: nowrap;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 16px;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.justify-content-between {
  -moz-box-pack: justify !important;
  justify-content: space-between !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5000;
}

.align-items-center {
  -moz-box-align: center !important;
  align-items: center !important;
}
.align-items-start {
  -moz-box-align: start !important;
  align-items: flex-start !important;
}

.shadow {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 3px 0 rgba(0, 0, 0, 0.1) !important;
}
.shadow-sm {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075) !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:not(:disabled):hover {
  color: #212529;
  text-decoration: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-secondary {
  font-weight: bold;
  color: #2a3642;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(42, 54, 66, 0.1),
    0 0 0 1px rgba(212, 215, 217, 0.5);
  border: 0;
}
.btn-secondary:hover {
  color: #2a3642;
  background-color: #ebeef0;
  box-shadow: 0 6px 12px rgba(42, 54, 66, 0.2),
    0 0 0 1px rgba(212, 215, 217, 0.5);
}
.btn-primary {
  font-weight: 700;
  color: #fff;
  background-color: #00a2ff;
  box-shadow: 0 4px 16px rgba(0, 113, 179, 0.2),
    inset 0 0 0 1px rgba(0, 113, 179, 0.15);
  border: 0;
}
.btn-primary:hover {
  box-shadow: 0 6px 12px rgba(0, 113, 179, 0.3),
    inset 0 0 0 1px rgba(0, 113, 179, 0.15);
  background-color: #a1daff;
  border-color: transparent;
  color: #fff;
}
.btn-primary:disabled {
  background-color: #a1daff;
  border-color: transparent;
  box-shadow: none;
}
.bg-transparent {
  background-color: transparent !important;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.modal-header {
  border-bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 16px;
  border-top-left-radius: calc(4.8px - 1px);
  border-top-right-radius: calc(4.8px - 1px);
  border-bottom: 0;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  -moz-box-flex: 1;
  flex: 1 1 auto;
  padding: 16px;
}
.modal-content {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4.8px;
  outline: 0;
}
.card {
  border-radius: 8px;
  overflow: hidden;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-clip: border-box;
  background-color: #fff;
}
.card-header {
  padding: 12px 20px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-title {
  margin-bottom: 12px;
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(4px - 1px);
  border-top-right-radius: calc(4px - 1px);
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 20px;
}
.card-footer {
  padding: 12px 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-link + .card-link {
  margin-left: 20px;
}
.card-text:last-child {
  margin-bottom: 0;
}
.btn-white {
  margin-bottom: 16px;
  font-weight: bold;
  color: #2a3642;
  background-color: #fff;
  box-shadow: 0 4px 16px rgba(42, 54, 66, 0.1), 0 0 0 1px rgba(42, 54, 66, 0.15);
  border: 0;
}
.btn-white:hover {
  background-color: #f4f5f5;
  box-shadow: 0 6px 12px rgba(42, 54, 66, 0.2), 0 0 0 1px rgba(42, 54, 66, 0.15);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-block {
  display: block;
  width: 100%;
}
textarea:focus,
button:focus {
  outline: none;
}

.btn-full-width:last-child {
  margin-top: 16px;
}
.close {
  float: right;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  user-select: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 3.2px rgba(0, 123, 255, 0.25);
}
.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
.nav-link > a {
  color: inherit;
  text-decoration: inherit;
}
.nav-item {
  white-space: nowrap;
}

.alert {
  position: relative;
  padding: 12px 20px;
  margin-bottom: 16px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.invalid-feedback {
  width: 100%;
  margin-top: 4px;
  font-size: 80%;
  color: #dc3545;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.w-auto {
  width: auto !important;
}
.w-100 {
  width: 100% !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.shadow-sm {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075) !important;
}
.text-white {
  color: #fff !important;
}
.text-danger {
  color: #dc3545 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.form-group {
  margin-bottom: 16px;
}
.form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(24px + 12px);
  background-image: url("data:image/svg+xml,%3csvg xmlns=http://www.w3.org/2000/svg width=12 height=12 fill=none stroke=%23dc3545 viewBox=0 0 12 12%3e%3ccircle cx=6 cy=6 r=4.5/%3e%3cpath stroke-linejoin=round d=M5.8 3.6h.4L6 6.5z/%3e%3ccircle cx=6 cy=8.2 r=.6 fill=%23dc3545 stroke=none/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(6px + 3px) center;
  background-size: calc(12px + 6px) calc(12px + 6px);
}
.mr-4,
.mx-4 {
  margin-right: 24px !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.ml-2,
.mx-2 {
  margin-left: 8px !important;
}
.m-0 {
  margin: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mt-2,
.my-2 {
  margin-top: 8px !important;
}
.ml-3,
.mx-3 {
  margin-left: 16px !important;
}
.mt-3,
.my-3 {
  margin-top: 16px !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-2,
.mx-2 {
  margin-right: 8px !important;
}
.mt-4,
.my-4 {
  margin-top: 24px !important;
}
.mb-4,
.my-4 {
  margin-bottom: 24px !important;
}
.mb-5,
.my-5 {
  margin-bottom: 48px !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.p-2 {
  padding: 8px !important;
}
.p-2 {
  padding: 8px !important;
}
.pl-4,
.px-4 {
  padding-left: 24px !important;
}
.pr-2,
.px-2 {
  padding-right: 8px !important;
}
.pb-2,
.py-2 {
  padding-bottom: 8px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.p-0 {
  padding: 0 !important;
}
.pb-4,
.py-4 {
  padding-bottom: 24px !important;
}
.pt-4 {
  padding-top: 24px !important;
}
.rounded {
  border-radius: 4px !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-12 {
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 328px) {
  .container {
    max-width: 328px;
  }
}
@media (min-width: 414px) {
  .container {
    max-width: 414px;
  }
}
@media (min-width: 500px) {
  .container {
    max-width: 500px;
  }
}
@media (min-width: 600px) {
  .container {
    max-width: 600px;
  }
}
@media (min-width: 680px) {
  .container {
    max-width: 680px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
  .d-md-block {
    display: block !important;
  }
  .col-md-6 {
    -moz-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-12 {
    -moz-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .d-md-flex {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }
  .col-lg-3 {
    -moz-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-9 {
    -moz-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-6 {
    -moz-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-nav {
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
  .p-lg-0 {
    padding: 0;
  }
}
@media (min-width: 1032px) {
  .container {
    max-width: 1032px;
  }
}
@media (min-width: 1384px) {
  .container {
    max-width: 1384px;
  }
}
@media (min-width: 1392px) {
  .container {
    max-width: 1392px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}

/**************/

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 16px;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 16px;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 8px;
  margin-left: 0;
}

blockquote {
  margin: 0 0 16px;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub {
  bottom: -4px;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 16px;
}

pre {
  margin-top: 0;
  margin-bottom: 16px;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 16px;
}

img {
  vertical-align: middle;
  border-style: none;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

button {
  border-radius: 0;
  white-space: nowrap;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 8px;
  font-size: 24px;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.slick-dots li {
  overflow: hidden;

  background: transparent;
  height: 4px;
  flex: 1;
  border-radius: 0px;
  top: -1px;
  margin: 0;
}
.slick-dots .slick-active {
  visibility: visible;
  background: rgb(50, 197, 255);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide {
  display: none;
}

.d-flex {
  display: flex;
}
.flex-flow-column {
  flex-flow: column;
}
.d-block {
  display: block;
}
.d-none {
  display: none;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.font-bold {
  font-weight: 700;
}

@media (max-width: 1031.98px) {
  .md-hidden {
    display: none
  }
}